import { z } from 'zod';

export const REQUIRED_MESSAGE = 'form_validation_required';
export const EMAIL_MESSAGE = 'emailMessage';
export const AGE_MESSAGE = 'ageMessage';
export const PASSWORD_LENGTH_MESSAGE = 'passwordLengthMessage';
export const PASSWORD_MESSAGE = 'passwordMessage';
export const MAX_MESSAGE = 'maxMessage';
export const VAT_MESSAGE = 'vatMessage';

export const optionalValidation = z.string().optional();

export const requiredValidation = z.string().min(1, {
  message: REQUIRED_MESSAGE,
});

export const numberValidation = z.number({
  required_error: REQUIRED_MESSAGE,
});

export const emailValidation = requiredValidation.email({
  message: EMAIL_MESSAGE,
});

export const nameValidation = requiredValidation.max(50, {
  message: MAX_MESSAGE,
});

export const dobValidation = z.string({ required_error: REQUIRED_MESSAGE }).refine((value) => {
  const today = new Date();
  const minAgeDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
  return new Date(value) <= minAgeDate;
}, AGE_MESSAGE);

export const passwordValidation = requiredValidation
  .min(6, PASSWORD_LENGTH_MESSAGE)
  .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, PASSWORD_MESSAGE);

export const requiredBooleanValidation = z
  .boolean()
  .default(false)
  .refine((value) => value === true, REQUIRED_MESSAGE);

export const optionalBooleanValidation = z.boolean().default(false).optional();

export const postalCodeValidation = z
  .string({
    required_error: REQUIRED_MESSAGE,
  })
  .max(10, {});

export const arrayValidation = z.array(z.string()).min(1);
