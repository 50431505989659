import { useIntl } from 'react-intl';
import { Button, ButtonProps } from 'ui/Button/Button';
import { Icon } from 'ui/Icon/Icon';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { IconLookup } from 'utils/fontAwesome';

type SearchNoResultsProps = {
  buttons?: ButtonProps[];
  children?: React.ReactNode;
  className?: string;
  icon?: IconLookup;
  showChecklist?: boolean;
  title?: string;
};

const SearchNoResults = ({
  buttons,
  children,
  className,
  icon = { name: 'telescope', styling: 'far' },
  showChecklist,
  title,
}: SearchNoResultsProps) => {
  const { formatMessage } = useIntl();

  const checklistIds = ['search_no_results_check_1', 'search_no_results_check_2', 'search_no_results_check_3'];

  return (
    <div className={cn('my-12 rounded bg-success p-12', className)}>
      <div className="flex max-w-md flex-col gap-6">
        <Icon className="size-16 text-white" icon={icon} />
        {title && (
          <Text className="text-white" type="h2">
            {title}
          </Text>
        )}
        {showChecklist && (
          <ul className="no-search-results-checklist">
            {checklistIds.map((id) => (
              <li key={id} className="flex items-center gap-4 text-white">
                <Icon className="size-6" icon={{ name: 'check', styling: 'fas' }} />
                <Text className="text-white">{formatMessage({ id })}</Text>
              </li>
            ))}
          </ul>
        )}
        {children}
        {buttons?.length && (
          <div className="inline-flex flex-col gap-3">
            {buttons.map(({ className, ...buttonProps }, index) => (
              <Button
                key={index}
                className={cn(
                  'w-fit',
                  buttonProps.variant === 'outlined'
                    ? 'border-white bg-success text-white hover:border-success hover:bg-white hover:text-success'
                    : '',
                  className,
                )}
                {...buttonProps}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchNoResults;
